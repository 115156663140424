import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../common/components/Box';
import Text from '../../common/components/Text';
import Heading from '../../common/components/Heading';
import { Image } from 'antd';
import Container from '../../common/components/UI/Container';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { UNIVERSITIES, ACCREDATIONS } from '../../data/index';

import {
  TestimonialWrapper,
  TestimonialItem,
  TestimonialMeta,
  AccredationsWrapper,
  AccredationsItem,
  AuthorInfo,
  AuthorImage,
  Grid,
  Item
} from './university.style';

const UniversitySection = ( {
  sectionWrapper,
  secTitleWrapper,
  secText,
  secHeading,
} ) => {

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear"
  };

  return (
    <Box { ...sectionWrapper } as="section" id="testimonial_section">
      <Container>
        <Box { ...secTitleWrapper }>
          <Heading
            { ...secHeading }
            content="Universities"
          />
        </Box>
        <TestimonialWrapper>
          <Slider { ...settings }>
            { UNIVERSITIES.map( ( item, index ) => (
              <div key={ index }>
                <TestimonialItem>
                  <AuthorImage>
                    <Image
                      src={ item.avatar }
                      alt={ `reviewer-image-${ index }` }
                      preview={ false }
                    />
                  </AuthorImage>
                </TestimonialItem>
              </div>
            ) ) }
          </Slider>
        </TestimonialWrapper>
        <Box { ...secTitleWrapper }>
          <Heading
            { ...secHeading }
            content="Accredations"
          />
        </Box>
        <Grid>
          { ACCREDATIONS.map( ( item, index ) => (
            <div key={ index }>
              <Item>
                <figure>
                  <Image
                    src={ item.avatar }
                    alt={ `reviewer-image-${ index }` }
                    preview={ false }
                  />
                </figure>
              </Item>
            </div>
          ) ) }
        </Grid>

      </Container>
    </Box>
  );
};

UniversitySection.propTypes = {
  sectionHeader: PropTypes.object,
};

UniversitySection.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '80px', '90px', '100px', '100px'],
    pb: ['60px', '80px', '90px', '100px', '100px'],
  },
  secTitleWrapper: {
    mb: ['40px', '60px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#ff4362',
    mb: '12px',
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px', '36px', '36px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    ml: 'auto',
    mr: 'auto',
    lineHeight: '1.12',
    width: '500px',
    maxWidth: '100%',
  },
  reviewTitle: {
    fontSize: ['15px', '16px'],
    fontWeight: '500',
    color: '#343d48',
    lineHeight: '1.5',
    mb: '13px',
  },
  review: {
    fontSize: ['16px', '19px'],
    fontWeight: '300',
    color: '#343d48',
    lineHeight: '1.7',
    mb: 0,
  },
  name: {
    fontSize: ['14px', '16px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '8px',
  },
  designation: {
    fontSize: ['12px', '14px'],
    color: '#6f7a87',
    mb: 0,
  },
};

export default UniversitySection;
