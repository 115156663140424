import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  BASE_URL, CREATE_USER_ASSESSMENT_RESPONSE
} from '../RoutesContainer/constants';

const useCreateUserAssessmentResponse = () => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  
  const API_URL = `${BASE_URL}${CREATE_USER_ASSESSMENT_RESPONSE}`;

  const CreateUserAssessmentResponse = async (userId, formId, iscompleted) => {
    setIsLoading(true);

    const requestBody = {
      user_id: userId,
      form_id: formId,
      is_completed: Number(iscompleted),
    };

    try {
      const res = await axios.post(API_URL, requestBody);
      setResponse(res.data);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return { response, error, isLoading, CreateUserAssessmentResponse };
};

export default useCreateUserAssessmentResponse;