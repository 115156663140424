export const uni1 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni1';
export const uni2 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni2';
export const uni3 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni3';
export const uni4 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni4';
export const uni5 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni5';
export const uni6 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni6';
export const uni7 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni7';
export const uni8 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni8';
export const uni9 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni9';
export const uni10 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni10';
export const uni11 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni11';
export const uni12 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni12';
export const uni13 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni13';
export const uni14 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni14';
export const uni15 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni15';
export const uni16 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni16';
export const uni17 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni17';
export const uni18 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni18';
export const uni19 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/uni19';

export const acc1 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/acc1';
export const acc2 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/acc2';
export const acc3 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/acc3';
export const acc4 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/acc4';
export const acc5 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/acc5';
export const acc6 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/acc6';
export const acc7 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/acc7';
export const acc8 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/acc8';

export const bannerImageLandingPage = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/bannerImageLandingPage';

export const feature1 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/feature1';
export const feature2 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/feature2';
export const feature3 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/feature3';
export const feature4 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/feature4';
export const feature5 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/feature5';
export const feature6 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/feature6';

export const aboutTheTestImage = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/aboutUsImage';

export const step1 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/step1';
export const step2 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/step2';
export const step3 = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/step3';

export const UniversityChicago = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/UniversityChicago';
export const LSE = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/LSE';
export const BrownUniversity = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/BrownUniversity';
export const cornell = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/cornell';

export const Logo = 'https://stride-survey-assets.s3.amazonaws.com/jenNext/gennextlogo';