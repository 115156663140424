import React, { useState, useEffect } from 'react';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import Container from '../../common/components/UI/Container';
import { bannerData } from '../../data/index';
import Section, {
  BannerContent, BannerContentWrapper, BannerText, Figure, FormContent,
  StyledForm, StyledLabel, StyledInput, StyledButton
} from './banner.style';
import Button from '../../common/components/Button';
import { notification, Checkbox } from 'antd';
import useCreateUserAssessmentResponse from '../../api/CreateUserAccessment';
import useCreateUserQuestionResponse from '../../api/CreateUserQuestionResponse';
import useFetchQuestion from '../../api/FetchQuestions';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useNavigate } from 'react-router-dom';
import './style.css';

const Banner = () => {
  const { title, text, button, news, newsLabel } = bannerData;

  const [userAssessmentId, setUserAssessmentId] = useState( 0 );
  const [isChecked, setIsChecked] = useState( false );
  const [isCorrectNumber, setIsCorrectNumber] = useState( false );
  const navigate = useNavigate();

  const form_id = 216;
  const user_id = 0;
  const iscompleted = 1;

  const { data, isLoading, isError } = useFetchQuestion( form_id );
  const { response, error, CreateUserAssessmentResponse } = useCreateUserAssessmentResponse()
  const [createUserQuestionResponse, formSubmitted, fail] = useCreateUserQuestionResponse();

  useEffect( () => {
    if ( data && !response ) {
      CreateUserAssessmentResponse( user_id, form_id, iscompleted );
    }
  }, [data] )

  useEffect( () => {
    if ( response ) {
      setUserAssessmentId( response.id )
    }
  }, [response] )

  useEffect( () => {
    if ( formSubmitted ) {
      notification.success( {
        message: 'Form Submitted Successfuly',
        description: ''
      } );
      navigate( '/thank_you' );
    }
  }, [formSubmitted] )

  const handleSubmit = async ( event ) => {
    if ( isCorrectNumber ) {
      event.preventDefault();
      const formData = new FormData( event.target.form );
      const data = {};
      let hasEmptyField = false;
      let invalidEmail = false;

      // Check if any required fields are empty
      formData.forEach( ( value, key ) => {
        if ( !value && event.target.form.querySelector( `[name="${ key }"]` ).required ) {
          hasEmptyField = true;
        }
        data[key] = value;
      } );

      // Email validation
      const emailRegex = /^[a-zA-Z0-9]+([\.-]?[a-zA-Z0-9]+){0,5}@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]+$/;;
      if ( data.email && !emailRegex.test( data.email ) ) {
        invalidEmail = true;
      }

      if ( hasEmptyField || invalidEmail ) {
        let errorMessage = '';
        if ( hasEmptyField ) {
          errorMessage += 'Please fill in all required fields. ';
        }
        if ( invalidEmail ) {
          errorMessage += 'Please enter a valid email address (e.g., johndoe@example.com). ';
        }
        alert( errorMessage );
        return;
      }

      const questionMap = {
        fullName: 8952,
        email: 8953,
        phone: 8955,
        city: 8954,
      };

      const questionResponses = Object.keys( questionMap ).map( ( key ) => {
        const questionID = questionMap[key];
        const textResponse = data[key];
        return {
          question_id: questionID,
          text_response: textResponse,
          time_taken: 0,
          user_assessment_response_id: userAssessmentId,
          options: [],
          attempted: true,
        };
      } );

      try {
        await createUserQuestionResponse( questionResponses );
        event.target.form.reset();
      } catch ( error ) {
        console.error( 'Error submitting form:', error );
        alert( 'Error submitting form. Please try again.' );
      }
    } else {
      alert( 'Incorrect Mobile Number' );
    }
  };


  const handleCheckbox = ( e ) => {
    setIsChecked( !isChecked );
  };


  return (
    <Section id="home">
      <Container width="1400px" paddingTop="10px">
        <BannerContentWrapper>
          <BannerContent>
            <Heading
              className="animate__animated animate__fadeInUp banner-title"
              content={ title }
            />
            <BannerText>
              <div className="animate__animated animate__fadeInUp">
                { text }
              </div>
            </BannerText>
          </BannerContent>
          <FormContent>
            <StyledForm onSubmit={ handleSubmit }>
              <StyledLabel htmlFor="fullName">Full Name:</StyledLabel>
              <StyledInput type="text" id="fullName" name="fullName" required />

              <StyledLabel htmlFor="email">Email:</StyledLabel>
              <StyledInput type="email" id="email" name="email" required />

              <StyledLabel htmlFor="phoneNumber">Phone Number:</StyledLabel>
              <PhoneInput
                isValid={ ( value, country ) => {
                  if ( value.match( /^(\+?91|0)?[6789]\d{10}$/ ) ) {
                    setIsCorrectNumber( false )
                    return 'Invalid value: ' + value + ', ' + country.name;
                  } else {
                    setIsCorrectNumber( true )
                    return true;
                  }
                } }
                required
                country="in"
                inputProps={ {
                  name: 'phone',
                  required: true,
                  autoFocus: true,
                } }
                containerStyle={ { marginBottom: '16px' } }
              />

              <StyledLabel htmlFor="city">City:</StyledLabel>
              <StyledInput type="text" id="city" name="city" required />
              <Checkbox onChange={ handleCheckbox }>I agree to the <a href='https://jennextmentors.com/privacy_policy'>Terms and Conditions</a></Checkbox>
              <StyledButton type="submit" onClick={ handleSubmit } disabled={ !isChecked }>
                Book a Free Consultation!
              </StyledButton>
            </StyledForm>
          </FormContent>
        </BannerContentWrapper>
      </Container>
    </Section>
  );
};

export default Banner;
