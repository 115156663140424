import React from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import JenNextMentorsFormLandingPage from "./LandingPageMain";
import ThankYouSection from "../components/ThankYouPage";

const RoutesContainer = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <JenNextMentorsFormLandingPage /> } />
                <Route path="/thank_you" element={ <ThankYouSection /> } />
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesContainer;