import styled from 'styled-components';

export const BannerWrapper = styled.section `
  position: relative;
  width: 100%;
  height : 100vh;
  padding-top: 60px;
  background-image: linear-gradient(117deg, #E0EAFF 0%, #FFE8E9 100%);
  @media (max-width: 767px) {
    padding-top: 50px;
  }

  @media (max-width: 480px) {
    margin-bottom: 0;
    padding-bottom: 0px;
  }

  .container {
    display : flex;
    flex-direction : column;
    align-items : center;
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;

    > span {
      font-family: DM Sans;
      font-style: normal;
      font-weight: 500;
      display: block;
      color: #000;
      opacity: 0.6;
      font-size: 15px;
      margin-top: 15px;
    }
  }
  h3 {
    margin: 0;
    color: #000;
    font-style: normal;
    font-weight: bold;
    font-size: 62px;
    line-height: 70px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    white-space: pre-line;

    @media (max-width: 1440px) {
      font-size: 50px;
      line-height: 1.2;
    }

    @media (max-width: 1024px) {
      font-size: 45px;
      line-height: 1.2;
    }

    @media (max-width: 690px) {
      font-size: 40px;
      line-height: 1.2;
      width: 70%;
      white-space: normal;
    }

    @media (max-width: 575px) {
      font-size: 35px;
      line-height: 1.2em;
      width: 80%;
    }

    @media (max-width: 425px) {
      width: 100%;
    }
  }
  p {
    margin: 0;
    color: #000;
    opacity: 0.8;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 1.88;
    width: 42%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;

    @media (max-width: 1024px) {
      width: 48%;
    }

    @media (max-width: 690px) {
      width: 55%;
    }

    @media (max-width: 575px) {
      width: 65%;
      line-height: 1.5;
    }

    @media (max-width: 425px) {
      width: 80%;
    }
  }
  .imageWrapper {
    display: inline-block;
    position: relative;
    bottom: -135px;
    margin-top: -115px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.15);

    @media (max-width: 480px) {
      bottom: 0;
      margin-top: 40px;
    }
  }
  .reusecore__button {
    background-color: #00005c;
    padding: 17.5px 20px;
    border-radius: 5px;
    transition: 500ms;
    position: relative;
    z-index: 10;

    &:hover {
      color: #00005c;
      background-color: #fff;
    }
    span {
      font-family: DM Sans;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      display: flex;
      align-items: center;
    }
  }
`;

export const StyledFigure = styled.div `
    @media (min-width: 768px) {
        width: 900px;
        height: 500px;
    }

    @media (max-width: 767px) {
        width: 100%;
        height: auto;
    }
`;

export const StyledButton = styled.button `
padding: 20px 50px;
background-color: #CD121B;
color: white;
border: none;
border-radius: 4px;
width : max-content;
font-weight : 800;
font-size: 20px;
cursor: pointer;
transition: background-color 0.3s;
margin-top : 20px;

&:hover {
    background-color: #0056b3;
}

@media (max-width: 575px) {
  padding: 14px 14px;
    }
`;