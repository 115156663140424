import { useState } from 'react';
import axios from 'axios';
import {
    BASE_URL, CREATE_USER_QUESTION_RESPONSE
} from '../RoutesContainer/constants';

const useCreateUserQuestionResponse = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const API_URL = `${ BASE_URL }${ CREATE_USER_QUESTION_RESPONSE }`;

    const createUserQuestionResponse = async ( questionResponses ) => {
        setIsLoading( true );

        try {
            const res = await axios.post( API_URL, questionResponses );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
            setResponse( null );
        } finally {
            setIsLoading( false );
        }
    };

    return [createUserQuestionResponse, response, error, isLoading];
};

export default useCreateUserQuestionResponse;

