import {
  uni1,
  uni2,
  uni3,
  uni4,
  uni5,
  uni6,
  uni7,
  uni8,
  uni9,
  uni10,
  uni11,
  uni12,
  uni13,
  uni14,
  uni15,
  uni16,
  uni17,
  uni18,
  uni19,
  acc1,
  acc2,
  acc3,
  acc4,
  acc5,
  acc6,
  acc7,
  acc8,
  feature1,
  feature2,
  feature3,
  feature4,
  feature5,
  feature6,
  step1,
  step2,
  step3,
  UniversityChicago,
  LSE,
  BrownUniversity,
  cornell,
  Logo,
} from '../RoutesContainer/Imagelinks';

export const bannerData = {
  news: 'NEWS',
  newsLabel: 'We have updated our term & condition policy',
  title: <>Boost your chances to get into top universities</>,
  text: <>Feeling Overwhelmed? Our <strong> Expert Mentors</strong> Simplify Your Journey to Global Education. From profile evaluation to scholarship support, JenNext is your one-stop solution for all things study abroad.</>,
};

export const BANNER_DATA = {
  title: 'Your responses have been submitted.',
  text: 'Our counselors will reach out to you.',
  tagLine: 'Apps Available to download on',
}

export const FOOTER_DATA = {
  logo: Logo,
  menu: [
    {
      link: '#',
      label: 'Support',
    },
    {
      link: '#',
      label: 'About Us',
    },
    {
      link: '#',
      label: 'Privacy',
    },
    {
      link: '#',
      label: 'Contact',
    },
  ],
  social: [
    {
      icon: Logo,
      link: '#',
    },
  ],
};

export const MONTHLY_PRICING_TABLE = [{
  name: 'Step 1',
  description: <>University Selection:<br />Unlock Potential</>,
  price: (
    <>
      Discover your perfect university match with expert guidance. Our mentors provide personalized insights and insider knowledge to align your ambitions with the ideal institution.<br /><br /><br /><br />
      <img src={ step1 } />
    </>
  ),
},
{
  name: 'Step 2',
  description: <>Application Preparation:<br />Stand Out</>,
  price: (
    <>
      Craft standout applications with our mentorship. Benefit from strategic advice and tailored feedback to highlight your unique strengths and make a lasting impression.<br /><br />
      <img src={ step2 } />
    </>
  ),
},
{
  name: 'Step 3',
  description: <>Secure Admission:<br />Cross the Finish Line</>,
  price: (
    <>
      Navigate the admission process with confidence. Our mentors guide you through decision-making, visa processes, and a smooth transition to your dream university.<br /><br /><br /><br />
      <img src={ step3 } />
    </>
  ),
},
];

export const FEATURES = [{
  icon: feature1,
  title: 'Course and Institution Selection: Unlock Academic Excellence',
  description: 'Expert guidance to identify courses and universities that resonate with your career vision and academic prowess. Utilize our insider knowledge and comprehensive databases to find your ideal  match. ',
},
{
  icon: feature2,
  title: 'Career Pathway Mapping: Future-Proof Your Ambitions',
  description: 'Leverage our years of experience through in-depth discussions on global career opportunities and pathways in your chosen field.',
},
{
  icon: feature3,
  title: 'Financial Empowerment: Scholarships & Budgeting Insights',
  description: 'Tailored advice on scholarships, grants, and effective budgeting to make global education affordable. Leverage expert insights to make your study abroad journey feasible.',
},
{
  icon: feature4,
  title: 'Visa and Cultural Acclimation: Smooth Transition Assured',
  description: 'Expert guidance in document verification and application procedures. Comprehensive pre-departure orientation for a seamless cultural transition.',
},
{
  icon: feature5,
  title: 'Application & Exam Mastery: Simplify & Succeed',
  description: 'Step-by-step mentorship to streamline your application, enhancing your profile for competitive edge. Targeted strategies for standardised test preparation to maximize your performance.',
},
{
  icon: feature6,
  title: 'Settlement Support: Your Comfort, Our Priority',
  description: 'Our support extends beyond admissions, We offer  assistance in finding accommodation, navigating health insurance, and more.',
},
];


export const USERTESTIMONIALS = [
  {
    review:
      'I am extremely grateful for the team at JenNext Mentors to have helped me enrol in the world’s top public policy course at a university that I believe will bring the best out of me. JenNext Mentors helped me view my application from the university point-of-view and focus on areas that required improvement.',
    name: 'Madhuri Sharma',
    designation: 'Master of Public Policy, University of Chicago',
    avatar: UniversityChicago,
  },
  {
    review:
      'JenNext Mentors provided me with such comprehensive assistance including one-one counselling, examinations, preparations, shortlisting universities and much more. I couldn\'t be more thankful as I am about start my undergraduate studies in September 2021 at Brown University. Thank you JenNext Mentors for everything!',
    name: 'Vedant Bhatt',
    designation: 'Liberal Arts, Brown University.',
    avatar: BrownUniversity,
  },
  {
    review:
      'JNM helped me build a great profile to apply to universities, which helped in secure an offer  from the LSE. Everyone at JenNext was extremely supportive and friendly throughout the process.',
    name: 'Devang Chaturvedi',
    designation: 'Economics, LSE',
    avatar: LSE,
  },
  {
    review:
      'JenNext Mentors helped me to their best possible level in achieving the desired academic results by getting me enrolled into Cornell University.  ',
    name: 'Aarushi Mehta',
    designation: 'Computer Science, Cornell University',
    avatar: cornell,
  },
];

export const UNIVERSITIES = [
  {
    avatar: uni1,
  },
  {
    avatar: uni2,
  },
  {
    avatar: uni3,
  },
  {
    avatar: uni4,
  },
  {
    avatar: uni5,
  },
  {
    avatar: uni6,
  },
  {
    avatar: uni7,
  },
  {
    avatar: uni8,
  },
  {
    avatar: uni9,
  },
  {
    avatar: uni10,
  },
  {
    avatar: uni11,
  },
  {
    avatar: uni12,
  },
  {
    avatar: uni13,
  },
  {
    avatar: uni14,
  },
  {
    avatar: uni15,
  },
  {
    avatar: uni16,
  },
  {
    avatar: uni17,
  },
  {
    avatar: uni18,
  },
  {
    avatar: uni19,
  },
];


export const ACCREDATIONS = [
  {
    avatar: acc1,
  },
  {
    avatar: acc2,
  },
  {
    avatar: acc3,
  },
  {
    avatar: acc4,
  },
  {
    avatar: acc5,
  },
  {
    avatar: acc6,
  },
  {
    avatar: acc7,
  },
  {
    avatar: acc8,
  },
];
