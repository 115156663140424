import React from 'react';
import Box from '../../common/components/Box';
import Heading from '../../common/components/Heading';
import Text from '../../common/components/Text';
import Container from '../../common/components/UI/Container';
import Fade from 'react-reveal/Fade';

import { BannerWrapper, StyledFigure, StyledButton } from './bannerSection.style';
import Lottie from 'lottie-react';
import animationData from '../../common/assets/Animation/Animation - 1708599606479.json';
import { BANNER_DATA } from '../../data/index';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';

const ThankYouSection = () => {
  return (
    <>
      <Helmet>
        <script>
          { `
            gtag('event', 'conversion', {'send_to': 'AW-11435487404/9dlfCIyot60ZEKzh7swq'});
          `}
        </script>
      </Helmet>
      <BannerWrapper id="banner_section">
        <Container key={ `banner` }>
          <Heading content={ BANNER_DATA.title } as="h3" />
          <Text content={ BANNER_DATA.text } />
          <StyledFigure>
            <Lottie animationData={ animationData } loop autoplay />
          </StyledFigure>
          {/*<StyledButton type="submit">Book a Free Consultation!</StyledButton>*/ }
        </Container>
      </BannerWrapper>
    </>
  );
};

export default ThankYouSection;
